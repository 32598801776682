import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

export const orgName = {
  localhost: {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'http://localhost:4200/dashboard',
  },
  'tandia.portal.uat.asappbanking.com': {
    clientId: '19242b3a-f800-41b1-b68a-114868889a20',
    authority:
      'https://asappuatb2c.b2clogin.com/asappuatb2c.onmicrosoft.com/B2C_1A_SIGNIN',
    resetPasswordAuthority:
      'https://asappuatb2c.b2clogin.com/asappuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://tandia.portal.uat.asappbanking.com/dashboard',
  },
  'cvcu.portal.uat.asappbanking.com': {
    clientId: '87d6d0a6-a4c7-4fb5-8958-de47439ea078',
    authority:
      'https://cvcuuatb2c.b2clogin.com/cvcuuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cvcuuatb2c.b2clogin.com/cvcuuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cvcu.portal.uat.asappbanking.com/dashboard',
  },
  'asappteamportaluidev.azurewebsites.net': {
    clientId: 'e0c43de7-79b2-408f-9a42-d92852ae3741',
    authority:
      'https://asappdevb2c.b2clogin.com/asappdevb2c.onmicrosoft.com/B2C_1_local_signin',
    resetPasswordAuthority:
      'https://asappdevb2c.b2clogin.com/asappdevb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://asappteamportaluidev.azurewebsites.net/dashboard',
  },
  'tandia.portal.qa.asappbanking.com': {
    clientId: '7213ac4f-d35c-4877-b0cc-b8e6b1cf31f9',
    authority:
      'https://asappdevb2c.b2clogin.com/asappdevb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdevb2c.b2clogin.com/asappdevb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://tandia.portal.qa.asappbanking.com/dashboard',
  },
  '1stchoicesavings.portal.qa.asappbanking.com': {
    clientId: '7dc026d0-4fea-4cce-b811-24f00b1c744e',
    authority:
      'https://1stchoicesavingsqab2c.b2clogin.com/1stchoicesavingsqab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://1stchoicesavingsqab2c.b2clogin.com/1stchoicesavingsqab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://1stchoicesavings.portal.qa.asappbanking.com/dashboard',
  },
  'cvcu.portal.qa.asappbanking.com': {
    clientId: 'adf09ff1-a2e7-4a01-b3e2-5d0bb68dbbe9',
    authority:
      'https://cvcuqab2c.b2clogin.com/cvcuqab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cvcuqab2c.b2clogin.com/cvcuqab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cvcu.portal.qa.asappbanking.com/dashboard',
  },
  '1stchoicesavings.portal.uat.asappbanking.com': {
    clientId: 'b9838467-3248-4ff7-a53e-1281956c509f',
    authority:
      'https://1stchoicesavingsuatb2c.b2clogin.com/1stchoicesavingsuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://1stchoicesavingsuatb2c.b2clogin.com/1stchoicesavingsuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://1stchoicesavings.portal.uat.asappbanking.com/dashboard',
  },
  'lakeview.portal.uat.asappbanking.com': {
    clientId: 'a5f8ea96-5c03-447f-9e7f-78430de8db95',
    authority:
      'https://lakeviewuatb2c.b2clogin.com/lakeviewuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://lakeviewuatb2c.b2clogin.com/lakeviewuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://lakeview.portal.uat.asappbanking.com/dashboard',
  },
  'tpcu.portal.uat.asappbanking.com': {
    clientId: 'c1f8f817-cdb6-44c6-9fa6-07991a7b8188',
    authority:
      'https://tpcuuatb2c.b2clogin.com/tpcuuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tpcuuatb2c.b2clogin.com/tpcuuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://tpcu.portal.uat.asappbanking.com/dashboard',
  },
  'duca.portal.uat.asappbanking.com': {
    clientId: 'bada1656-e400-48ee-8476-0baa8de16f99',
    authority:
      'https://ducauatb2c.b2clogin.com/ducauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://ducauatb2c.b2clogin.com/ducauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://duca.portal.uat.asappbanking.com/dashboard',
  },
  'encompass.portal.uat.asappbanking.com': {
    clientId: 'b20642a1-7683-46f1-9b66-74b8bc0e1781',
    authority:
      'https://encompassuatb2c.b2clogin.com/encompassuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://encompassuatb2c.b2clogin.com/encompassuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://encompass.portal.uat.asappbanking.com/dashboard',
  },
  'ncu.portal.uat.asappbanking.com': {
    clientId: '0d9fe4b7-ef23-4449-987d-223fbf6d5146',
    authority:
      'https://ncuuatb2c.b2clogin.com/ncuuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://ncuuatb2c.b2clogin.com/ncuuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://ncu.portal.uat.asappbanking.com/dashboard',
  },
  'pccu.portal.uat.asappbanking.com': {
    clientId: 'e01a1da8-a654-49a9-a4be-fbdae56995f2',
    authority:
      'https://pccuuatb2c.b2clogin.com/pccuuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://pccuuatb2c.b2clogin.com/pccuuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://pccu.portal.uat.asappbanking.com/dashboard',
  },
  'prospera.portal.uat.asappbanking.com': {
    clientId: '98a85678-6f5f-4966-8bab-25d9fa22104b',
    authority:
      'https://prosperauatb2c.b2clogin.com/prosperauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://prosperauatb2c.b2clogin.com/prosperauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://prospera.portal.uat.asappbanking.com/dashboard',
  },
  'steinbach.portal.uat.asappbanking.com': {
    clientId: 'f507fd42-7c9e-4b5f-bb79-d8849198ce6e',
    authority:
      'https://steinbachuatb2c.b2clogin.com/steinbachuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://steinbachuatb2c.b2clogin.com/steinbachuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://steinbach.portal.uat.asappbanking.com/dashboard',
  },
  'luminus.portal.uat.asappbanking.com': {
    clientId: '9706fcad-29d9-407d-a642-0723829a892c',
    authority:
      'https://luminusuatb2c.b2clogin.com/luminusuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://luminusuatb2c.b2clogin.com/luminusuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://luminus.portal.uat.asappbanking.com/dashboard',
  },
  'tandia.portal.staging.asappbanking.com': {
    clientId: '486137ef-25d6-4d21-8aa1-40e0990b1151',
    authority:
      'https://tandiastgngb2c.b2clogin.com/tandiastgngb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tandiastgngb2c.b2clogin.com/tandiastgngb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://tandia.portal.staging.asappbanking.com',
  },
  'leaguedata.ld.portal.test.asappbanking.com': {
    clientId: '8c7ff5cb-40ca-4610-b897-595e89679b70',
    authority:
      'https://leaguedatatestb2c.b2clogin.com/leaguedatatestb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatatestb2c.b2clogin.com/leaguedatatestb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://leaguedata.ld.portal.test.asappbanking.com/dashboard',
  },
  'leaguedata.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://leaguedata.ld.portal.uat.asappbanking.com/dashboard',
  },
  'acadiancreditu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://acadiancreditu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'advancesavings.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://advancesavings.ld.portal.uat.asappbanking.com/dashboard',
  },
  'bayviewnb.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://bayviewnb.ld.portal.uat.asappbanking.com/dashboard',
  },
  'beaubear.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://beaubear.ld.portal.uat.asappbanking.com/dashboard',
  },
  'blackvillecu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://blackvillecu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'caissepopclare.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://caissepopclare.ld.portal.uat.asappbanking.com/dashboard',
  },
  'cbcu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cbcu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'ccunl.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://ccunl.ld.portal.uat.asappbanking.com/dashboard',
  },
  'coastalfinancial.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://coastalfinancial.ld.portal.uat.asappbanking.com/dashboard',
  },
  'communitycreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://communitycreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'consolidatedcreditu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://consolidatedcreditu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'cua.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cua.ld.portal.uat.asappbanking.com/dashboard',
  },
  'bcufinancial.portal.uat.asappbanking.com': {
    clientId: '2b3a75a3-ab7b-4a84-84cb-1da74c01c660',
    authority:
      'https://bcuuatadb2c.b2clogin.com/bcuuatadb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://bcuuatadb2c.b2clogin.com/bcuuatadb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://bcufinancial.portal.uat.asappbanking.com/dashboard',
  },
  'dominioncreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://dominioncreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'eaglerivercu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://eaglerivercu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'eastcoastcu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://eastcoastcu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'easternedgecu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://easternedgecu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'eccu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://eccu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'gbccu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://gbccu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'hscunl.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://hscunl.ld.portal.uat.asappbanking.com/dashboard',
  },
  'inovacreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://inovacreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'lahaverivercreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://lahaverivercreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'lecu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://lecu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'malpequebaycreditu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://malpequebaycreditu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'morellcreditu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://morellcreditu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'nbtacu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://nbtacu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'newrosscreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://newrosscreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'newwaterfordcreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://newwaterfordcreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'nlcu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://nlcu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'northsydneycreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://northsydneycreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'omista.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://omista.ld.portal.uat.asappbanking.com/dashboard',
  },
  'princesscreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://princesscreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'progressivecu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://progressivecu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'provincialcu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://provincialcu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'provincialemployees.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://provincialemployees.ld.portal.uat.asappbanking.com/dashboard',
  },
  'pscu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://pscu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'reddyk.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://reddyk.ld.portal.uat.asappbanking.com/dashboard',
  },
  'souriscreditu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://souriscreditu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'stjosephscreditu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://stjosephscreditu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'sydneycreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://sydneycreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'teachersplus.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://teachersplus.ld.portal.uat.asappbanking.com/dashboard',
  },
  'thecreditu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://thecreditu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'tignishcreditu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://tignishcreditu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'valleycreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://valleycreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },
  'venturecu.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://venturecu.ld.portal.uat.asappbanking.com/dashboard',
  },
  'victorycreditunion.ld.portal.uat.asappbanking.com': {
    clientId: 'bf75dd0c-66ed-469c-ae90-3c4d2ff4dd20',
    authority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatauatb2c.b2clogin.com/leaguedatauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://victorycreditunion.ld.portal.uat.asappbanking.com/dashboard',
  },

  'digitalsavings.portal.uat.asappbanking.com': {
    clientId: '8a2d70a6-bf27-4d46-8cdf-b7539b321cb8',
    authority:
      'https://asappdigitalsavingsb2c.b2clogin.com/asappdigitalsavingsb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdigitalsavingsb2c.b2clogin.com/asappdigitalsavingsb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://digitalsavings.portal.uat.asappbanking.com/dashboard',
  },

  'tandiaportal.asappbanking.com': {
    clientId: 'e928f3fa-59c5-46f7-ac2a-6a5a717ab5ad',
    authority:
      'https://tandiab2c.b2clogin.com/tandiab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tandiab2c.b2clogin.com/tandiab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://tandiaportal.asappbanking.com/dashboard',
  },

  'directcuportal.asappbanking.com': {
    clientId: 'e928f3fa-59c5-46f7-ac2a-6a5a717ab5ad',
    authority:
      'https://tandiab2c.b2clogin.com/tandiab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tandiab2c.b2clogin.com/tandiab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://directcuportal.asappbanking.com/dashboard',
  },

  'portal.lvcu.ca': {
    clientId: 'e732c824-6a6f-4456-b398-d1befea51a77',
    authority:
      'https://lakeviewcu.b2clogin.com/lakeviewcu.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://lakeviewcu.b2clogin.com/lakeviewcu.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://portal.lvcu.ca/dashboard',
  },
  'portal.1stchoicesavings.ca': {
    clientId: '848eaabf-3bba-457c-99dc-5cbfcd8aef0d',
    authority:
      'https://firstchoicesavings.b2clogin.com/firstchoicesavings.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://firstchoicesavings.b2clogin.com/firstchoicesavings.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://portal.1stchoicesavings.ca/dashboard',
  },

  'engage.cvcu.bc.ca': {
    clientId: 'c01e2eac-a8ac-473b-9712-38c7fc8f856f',
    authority:
      'https://cvcub2c.b2clogin.com/cvcub2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cvcub2c.b2clogin.com/cvcub2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://engage.cvcu.bc.ca/dashboard',
  },

  'portal.northerncu.com': {
    clientId: '8939e197-de62-43fc-93aa-d56c3b921382',
    authority:
      'https://ncub2c.b2clogin.com/ncub2c.onmicrosoft.com/B2C_1A_SIGNIN',
    resetPasswordAuthority:
      'https://ncub2c.b2clogin.com/ncub2c.onmicrosoft.com/B2C_1A_PASSWORDRESET',
    redirectUri: 'https://portal.northerncu.com/dashboard',
  },

  'engage.scu.mb.ca': {
    clientId: 'e82eaaa1-6dd8-4fe1-b4e1-e283ecf6329b',
    authority:
      'https://steinbachb2c.b2clogin.com/steinbachb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://steinbachb2c.b2clogin.com/steinbachb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://engage.scu.mb.ca/dashboard',
  },

  'portal.pccu.ca': {
    clientId: 'f0736e88-614c-4ba2-81c4-f7df5404c995',
    authority:
      'https://pccub2c.b2clogin.com/pccub2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://pccub2c.b2clogin.com/pccub2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://portal.pccu.ca/dashboard',
  },

  'manage.duca.com': {
    clientId: '4402b3f3-46c2-490f-bb2b-91f32ba53c51',
    authority:
      'https://ducab2c.b2clogin.com/ducab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://ducab2c.b2clogin.com/ducab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://manage.duca.com/dashboard',
  },

  'asapp.tpcu.on.ca': {
    clientId: 'f2a7132b-c1ee-46ec-bad7-86abcce881d9',
    authority:
      'https://tpcub2c.b2clogin.com/tpcub2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tpcub2c.b2clogin.com/tpcub2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://asapp.tpcu.on.ca/dashboard',
  },

  'portal.encompasscu.ca': {
    clientId: 'e193d152-98b9-4562-b5e8-01d739314000',
    authority:
      'https://encompassb2c.b2clogin.com/encompassb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://encompassb2c.b2clogin.com/encompassb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://portal.encompasscu.ca/dashboard',
  },

  'asapp.prospera.ca': {
    clientId: 'ab50dcaa-cdba-449d-8a56-bb3c3324afbc',
    authority:
      'https://prosperab2c.b2clogin.com/prosperab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://prosperab2c.b2clogin.com/prosperab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://asapp.prospera.ca/dashboard',
  },

  'asapp-prod-teamportal-app.azurewebsites.net': {
    clientId: 'c01e2eac-a8ac-473b-9712-38c7fc8f856f',
    authority:
      'https://cvcub2c.b2clogin.com/cvcub2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cvcub2c.b2clogin.com/cvcub2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://asapp-prod-teamportal-app.azurewebsites.net/dashboard',
  },

  'acadiancreditu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://acadiancreditu.ldasapp.com/dashboard',
  },
  'advancesavings.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://advancesavings.ldasapp.com/dashboard',
  },
  'bayviewnb.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://bayviewnb.ldasapp.com/dashboard',
  },
  'beaubear.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://beaubear.ldasapp.com/dashboard',
  },
  'blackvillecu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://blackvillecu.ldasapp.com/dashboard',
  },
  'caissepopclare.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://caissepopclare.ldasapp.com/dashboard',
  },
  'cbcu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cbcu.ldasapp.com/dashboard',
  },
  'ccunl.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://ccunl.ldasapp.com/dashboard',
  },
  'coastalfinancial.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://coastalfinancial.ldasapp.com/dashboard',
  },
  'communitycreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://communitycreditunion.ldasapp.com/dashboard',
  },
  'consolidatedcreditu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://consolidatedcreditu.ldasapp.com/dashboard',
  },
  'cua.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cua.ldasapp.com/dashboard',
  },
  'dominioncreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://dominioncreditunion.ldasapp.com/dashboard',
  },
  'eaglerivercu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://eaglerivercu.ldasapp.com/dashboard',
  },
  'eastcoastcu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://eastcoastcu.ldasapp.com/dashboard',
  },
  'easternedgecu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://easternedgecu.ldasapp.com/dashboard',
  },
  'eccu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://eccu.ldasapp.com/dashboard',
  },
  'gbccu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://gbccu.ldasapp.com/dashboard',
  },
  'hscunl.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://hscunl.ldasapp.com/dashboard',
  },
  'inovacreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://inovacreditunion.ldasapp.com/dashboard',
  },
  'lahaverivercreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://lahaverivercreditunion.ldasapp.com/dashboard',
  },
  'lecu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://lecu.ldasapp.com/dashboard',
  },
  'malpequebaycreditu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://malpequebaycreditu.ldasapp.com/dashboard',
  },
  'morellcreditu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://morellcreditu.ldasapp.com/dashboard',
  },
  'nbtacu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://nbtacu.ldasapp.com/dashboard',
  },
  'newrosscreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://newrosscreditunion.ldasapp.com/dashboard',
  },
  'newwaterfordcreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://newwaterfordcreditunion.ldasapp.com/dashboard',
  },
  'nlcu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://nlcu.ldasapp.com/dashboard',
  },
  'northsydneycreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://northsydneycreditunion.ldasapp.com/dashboard',
  },
  'omista.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://omista.ldasapp.com/dashboard',
  },
  'princesscreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://princesscreditunion.ldasapp.com/dashboard',
  },
  'progressivecu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://progressivecu.ldasapp.com/dashboard',
  },
  'provincialcu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://provincialcu.ldasapp.com/dashboard',
  },
  'provincialemployees.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://provincialemployees.ldasapp.com/dashboard',
  },
  'pscu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://pscu.ldasapp.com/dashboard',
  },
  'reddyk.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://reddyk.ldasapp.com/dashboard',
  },
  'souriscreditu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://souriscreditu.ldasapp.com/dashboard',
  },
  'stjosephscreditu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://stjosephscreditu.ldasapp.com/dashboard',
  },
  'sydneycreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://sydneycreditunion.ldasapp.com/dashboard',
  },
  'teachersplus.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://teachersplus.ldasapp.com/dashboard',
  },
  'thecreditu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://thecreditu.ldasapp.com/dashboard',
  },
  'tignishcreditu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://tignishcreditu.ldasapp.com/dashboard',
  },
  'valleycreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://valleycreditunion.ldasapp.com/dashboard',
  },
  'venturecu.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://venturecu.ldasapp.com/dashboard',
  },
  'victorycreditunion.ldasapp.com': {
    clientId: 'a3c70af6-9c02-44cd-a39c-eaf3722d5160',
    authority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedataprodb2c.b2clogin.com/leaguedataprodb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://victorycreditunion.ldasapp.com/dashboard',
  },
  'steinbach.portal.staging.asappbanking.com': {
    clientId: '29275348-bbe2-4c1f-80aa-f0decd943972',
    authority:
      'https://steinbachstagingb2c.b2clogin.com/steinbachstagingb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://steinbachstagingb2c.b2clogin.com/steinbachstagingb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://steinbach.portal.staging.asappbanking.com/dashboard',
  },
  'pccu.portal.staging.asappbanking.com': {
    clientId: '85ca384e-33e1-4916-b170-6658b8041a61',
    authority:
      'https://pccustagingb2c.b2clogin.com/pccustagingb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://pccustagingb2c.b2clogin.com/pccustagingb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://pccu.portal.staging.asappbanking.com/dashboard',
  },
  'tpcu.portal.staging.asappbanking.com': {
    clientId: 'f2cb264b-e1b4-495e-bcff-2f0299926d34',
    authority:
      'https://tpcustaging.b2clogin.com/tpcustaging.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tpcustaging.b2clogin.com/tpcustaging.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://tpcu.portal.staging.asappbanking.com/dashboard',
  },
  'prospera.portal.staging.asappbanking.com': {
    clientId: 'd1cd5de5-1649-42dc-8c66-1de023d022c2',
    authority:
      'https://prosperastagingb2c.b2clogin.com/prosperastagingb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://prosperastagingb2c.b2clogin.com/prosperastagingb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://prospera.portal.staging.asappbanking.com/dashboard',
  },
  'lakeview.portal.staging.asappbanking.com': {
    clientId: '4703b581-095d-4640-afe2-d37f501eec03',
    authority:
      'https://lakeviewstagingb2c.b2clogin.com/lakeviewstagingb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://lakeviewstagingb2c.b2clogin.com/lakeviewstagingb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://lakeview.portal.staging.asappbanking.com/dashboard',
  },
  'duca.portal.staging.asappbanking.com': {
    clientId: 'd7404212-3ee8-4dc7-a49e-106dd2196fa7',
    authority:
      'https://ducastgb2c.b2clogin.com/ducastgb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://ducastgb2c.b2clogin.com/ducastgb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://duca.portal.staging.asappbanking.com/dashboard',
  },
  'ncu.portal.staging.asappbanking.com': {
    clientId: '156796ed-325a-4112-8851-1b2a8b1cf9a7',
    authority:
      'https://ncustgb2c.b2clogin.com/ncustgb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://ncustgb2c.b2clogin.com/ncustgb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://ncu.portal.staging.asappbanking.com/dashboard',
  },
  'cvcu.portal.staging.asappbanking.com': {
    clientId: 'fe4db09b-9909-4fec-95ba-a572db293626',
    authority:
      'https://cvcustgb2c.b2clogin.com/cvcustgb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cvcustgb2c.b2clogin.com/cvcustgb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cvcu.portal.staging.asappbanking.com/dashboard',
  },
  '1stchoicesavings.portal.staging.asappbanking.com': {
    clientId: 'e9309d82-1849-40d8-8f0c-f473a18bde96',
    authority:
      'https://1stchoicesavingsstgngb2c.b2clogin.com/1stchoicesavingsstgngb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://1stchoicesavingsstgngb2c.b2clogin.com/1stchoicesavingsstgngb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://1stchoicesavings.portal.staging.asappbanking.com/dashboard',
  },
  'leaguedata.ld.portal.staging.asappbanking.com': {
    clientId: 'd2763aa0-f762-414b-8af5-6e4d48ab83cd',
    authority:
      'https://leaguedatastagingb2c.b2clogin.com/leaguedatastagingb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://leaguedatastagingb2c.b2clogin.com/leaguedatastagingb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://leaguedata.ld.portal.staging.asappbanking.com/dashboard',
  },
  'softude-dev3.portal.qa.asappbanking.com': {
    clientId: 'f5b8d21f-50cf-4197-8b71-336d14e400dd',
    authority:
      'https://softudedev3b2c.b2clogin.com/softudedev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://softudedev3b2c.b2clogin.com/softudedev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://softude-dev3.portal.qa.asappbanking.com/dashboard',
  },
  'firstontario2.portal.uat.asappbanking.com': {
    clientId: 'd7b370d4-ce1f-4acc-951a-923f8ee252f7',
    authority:
      'https://firstontariouatb2c.b2clogin.com/firstontariouatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://firstontariouatb2c.b2clogin.com/firstontariouatb2c.onmicrosoft.com//B2C_1_ResetPassword',
    redirectUri: 'https://firstontario2.portal.uat.asappbanking.com/dashboard',
  },
  'asapp.portal.dev.asappbanking.com': {
    clientId: '34734e65-1708-4754-98da-87605c98fcd0',
    authority:
      'https://asappdev2b2c.b2clogin.com/asappdev2b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev2b2c.b2clogin.com/asappdev2b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://asapp.portal.dev.asappbanking.com/dashboard',
  },
  'sudbury.portal.uat.asappbanking.com': {
    clientId: 'dc40af8b-cecf-4460-9ae5-7679aba78171',
    authority:
      'https://sudburyuatb2c.b2clogin.com/sudburyuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://sudburyuatb2c.b2clogin.com/sudburyuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://sudbury.portal.uat.asappbanking.com/dashboard',
  },
  'khalsa.portal.uat.asappbanking.com': {
    clientId: '4485d4ca-f178-4f6d-92b4-d8909d68cf0d',
    authority:
      'https://khalsauatb2c.b2clogin.com/khalsauatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://khalsauatb2c.b2clogin.com/khalsauatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://khalsa.portal.uat.asappbanking.com/dashboard',
  },
  'asapp-test-crm-16.portal.uat.asappbanking.com': {
    clientId: '05a4791f-ebe8-42fa-b97e-3997dd80af4a',
    authority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://asapp-test-crm-16.portal.uat.asappbanking.com/dashboard',
  },
  'asapp-test-crm-16.portal.dev.asappbanking.com': {
    clientId: '05a4791f-ebe8-42fa-b97e-3997dd80af4a',
    authority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1A_SIGNIN',
    resetPasswordAuthority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://asapp-test-crm-16.portal.dev.asappbanking.com/dashboard',
  },
  'wealthview-test-crm-16.portal.dev.asappbanking.com': {
    clientId: '05a4791f-ebe8-42fa-b97e-3997dd80af4a',
    authority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://wealthview-test-crm-16.portal.dev.asappbanking.com/dashboard',
  },
  'cumish-test-crm-16.portal.dev.asappbanking.com': {
    clientId: '05a4791f-ebe8-42fa-b97e-3997dd80af4a',
    authority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumish-test-crm-16.portal.dev.asappbanking.com/dashboard',
  },
  'cumisd-test-crm-16.portal.dev.asappbanking.com': {
    clientId: '05a4791f-ebe8-42fa-b97e-3997dd80af4a',
    authority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumisd-test-crm-16.portal.dev.asappbanking.com/dashboard',
  },
  'rfs-test-crm-16.portal.dev.asappbanking.com': {
    clientId: '05a4791f-ebe8-42fa-b97e-3997dd80af4a',
    authority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://rfs-test-crm-16.portal.dev.asappbanking.com/dashboard',
  },
  'xchange-test-crm-16.portal.dev.asappbanking.com': {
    clientId: '05a4791f-ebe8-42fa-b97e-3997dd80af4a',
    authority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://xchange-test-crm-16.portal.dev.asappbanking.com/dashboard',
  },
  'mambu-test-crm-16.portal.dev.asappbanking.com': {
    clientId: '05a4791f-ebe8-42fa-b97e-3997dd80af4a',
    authority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrmb2c.b2clogin.com/asapptestcrmb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://mambu-test-crm-16.portal.dev.asappbanking.com/dashboard',
  },
  'prospera.portal.qa.asappbanking.com': {
    clientId: 'ea690964-6cee-4eae-a06f-3bb95a661ad3',
    authority:
      'https://prosperaqab2c.b2clogin.com/prosperaqab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://prosperaqab2c.b2clogin.com/prosperaqab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://prospera.portal.qa.asappbanking.com/dashboard',
  },
  'portal.luminusfinancial.com': {
    clientId: '8e7cfb5e-af97-4ba0-8baa-0b934b0eb4d0',
    authority:
      'https://luminusb2c.b2clogin.com/luminusb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://luminusb2c.b2clogin.com/luminusb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://portal.luminusfinancial.com/dashboard',
  },
  'portal.sudburycu.com': {
    clientId: '00b388c5-9f8f-4090-8098-5cb2ca3b0dc8',
    authority:
      'https://sudburyb2c.b2clogin.com/sudburyb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://sudburyb2c.b2clogin.com/sudburyb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://portal.sudburycu.com/dashboard',
  },
  'offers.portal.uat.asappbanking.com': {
    clientId: '6fc79500-7100-4b88-ac72-750e4eebfa23',
    authority:
      'https://offersuatb2c.b2clogin.com/offersuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://offersuatb2c.b2clogin.com/offersuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://offers.portal.uat.asappbanking.com/dashboard',
  },
  'tcufinancial.portal.uat.asappbanking.com': {
    clientId: '0f1329dd-ee75-47df-abba-0f482a557993',
    authority:
      'https://tcufinancialuatb2c.b2clogin.com/tcufinancialuatb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tcufinancialuatb2c.b2clogin.com/tcufinancialuatb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://tcufinancial.portal.uat.asappbanking.com/dashboard',
  },
  'portal.khalsacreditunion.ca': {
    clientId: '00618f18-dcc1-48de-9db2-2817ce1c6964',
    authority:
      'https://khalsab2c.b2clogin.com/khalsab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://khalsab2c.b2clogin.com/khalsab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://portal.khalsacreditunion.ca/dashboard',
  },
  'luminus.portal.staging.asappbanking.com': {
    clientId: '12ed7978-df3d-483d-a00c-e2efeebf27f7',
    authority:
      'https://luminusstgb2c.b2clogin.com/luminusstgb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://luminusstgb2c.b2clogin.com/luminusstgb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://luminus.portal.staging.asappbanking.com/dashboard',
  },
  'khalsa.portal.staging.asappbanking.com': {
    clientId: 'abdc7686-ad96-470f-bd76-2a45c377b512',
    authority:
      'https://khalsastgb2c.b2clogin.com/khalsastgb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://khalsastgb2c.b2clogin.com/khalsastgb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://khalsa.portal.staging.asappbanking.com/dashboard',
  },
  'digitalsavingsintl.portal.uat.asappbanking.com': {
    clientId: '8a2d70a6-bf27-4d46-8cdf-b7539b321cb8',
    authority:
      'https://asappdigitalsavingsb2c.b2clogin.com/asappdigitalsavingsb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdigitalsavingsb2c.b2clogin.com/asappdigitalsavingsb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://digitalsavingsintl.portal.uat.asappbanking.com/dashboard',
  },
  'asapp-test-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://asapp-test-17.portal.dev.asappbanking.com/dashboard',
  },
  'cumisd-test-crm-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumisd-test-crm-17.portal.dev.asappbanking.com/dashboard',
  },
  'cumish-test-crm-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumish-test-crm-17.portal.dev.asappbanking.com/dashboard',
  },
  'mambu-test-crm-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://mambu-test-crm-17.portal.dev.asappbanking.com/dashboard',
  },
  'rfs-test-crm-17.portal.dev.asappbanking.com': {
    clientId: 'c3ca595f-c398-4a8a-ae20-3986e2f6291a',
    authority:
      'https://asapptestcrm17adb2c.b2clogin.com/asapptestcrm17adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrm17adb2c.b2clogin.com/asapptestcrm17adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://rfs-test-crm-17.portal.dev.asappbanking.com/dashboard',
  },
  'wealthview-test-crm-17.portal.dev.asappbanking.com': {
    clientId: 'c3ca595f-c398-4a8a-ae20-3986e2f6291a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://wealthview-test-crm-17.portal.dev.asappbanking.com/dashboard',
  },
  'xchange-test-crm-17.portal.dev.asappbanking.com': {
    clientId: 'c3ca595f-c398-4a8a-ae20-3986e2f6291a',
    authority:
      'https://asapptestcrm17adb2c.b2clogin.com/asapptestcrm17adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrm17adb2c.b2clogin.com/asapptestcrm17adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://xchange-test-crm-17.portal.dev.asappbanking.com/dashboard',
  },
  'crossroadscu.portal.uat.asappbanking.com': {
    clientId: '8e8eb570-5074-475f-be5f-12f95666261d',
    authority:
      'https://crossroadscuuat.b2clogin.com/crossroadscuuat.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://crossroadscuuat.b2clogin.com/crossroadscuuat.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://crossroadscu.portal.uat.asappbanking.com/dashboard',
  },
  'cua-dna.portal.uat.asappbanking.com': {
    clientId: 'eb3fce41-3c88-48ca-b4e1-343fd000d0bb',
    authority:
      'https://cuadnauatadb2c.b2clogin.com/cuadnauatadb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cuadnauatadb2c.b2clogin.com/cuadnauatadb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cua-dna.portal.uat.asappbanking.com/dashboard',
  },
  'asapp-test-crm-17.portal.dev.asappbanking.com': {
    clientId: 'c3ca595f-c398-4a8a-ae20-3986e2f6291a',
    authority:
      'https://asapptestcrm17adb2c.b2clogin.com/asapptestcrm17adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asapptestcrm17adb2c.b2clogin.com/asapptestcrm17adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://asapp-test-crm-17.portal.dev.asappbanking.com/dashboard',
  },
  'cumisd-test-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cumisd-test-17.portal.dev.asappbanking.com/dashboard',
  },
  'cumish-test-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://cumish-test-17.portal.dev.asappbanking.com/dashboard',
  },
  'mambu-test-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://mambu-test-17.portal.dev.asappbanking.com/dashboard',
  },
  'rfs-test-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://rfs-test-17.portal.dev.asappbanking.com/dashboard',
  },
  'xchange-test-17.portal.dev.asappbanking.com': {
    clientId: '0781f2a3-ace6-4b5d-9e06-2078f9418f0a',
    authority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev3b2c.b2clogin.com/asappdev3b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://xchange-test-17.portal.dev.asappbanking.com/dashboard',
  },
  'cumisrfs.portal.uat.asappbanking.com': {
    clientId: '878c690d-fb1f-4c2a-b243-ac30c3d01d63',
    authority:
      'https://cumisdevb2c.b2clogin.com/cumisdevb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cumisdevb2c.b2clogin.com/cumisdevb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumisrfs.portal.uat.asappbanking.com/dashboard',
  },
  'cumish.portal.uat.asappbanking.com': {
    clientId: '878c690d-fb1f-4c2a-b243-ac30c3d01d63',
    authority:
      'https://cumisdevb2c.b2clogin.com/cumisdevb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cumisdevb2c.b2clogin.com/cumisdevb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumish.portal.uat.asappbanking.com/dashboard',
  },
  'cumisd.portal.uat.asappbanking.com': {
    clientId: '878c690d-fb1f-4c2a-b243-ac30c3d01d63',
    authority:
      'https://cumisdevb2c.b2clogin.com/cumisdevb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cumisdevb2c.b2clogin.com/cumisdevb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumisd.portal.uat.asappbanking.com/dashboard',
  },
  'cumis.portal.uat.asappbanking.com': {
    clientId: '878c690d-fb1f-4c2a-b243-ac30c3d01d63',
    authority:
      'https://cumisdevb2c.b2clogin.com/cumisdevb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cumisdevb2c.b2clogin.com/cumisdevb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumis.portal.uat.asappbanking.com/dashboard',
  },
  'base-site.portal.uat.asappbanking.com': {
    clientId: 'c9d449c9-a23f-4f04-8daa-9a1c0e92953f',
    authority:
      'https://basesiteuatadb2c.b2clogin.com/basesiteuatadb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://basesiteuatadb2c.b2clogin.com/basesiteuatadb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://base-site.portal.uat.asappbanking.com/dashboard',
  },
  'digitalsaving.portal.uat.asappbanking.com': {
    clientId: 'acf90e9b-a18e-41a5-bc3a-4da932fdf5e0',
    authority:
      'https://digitalsavinguatadb2c.b2clogin.com/digitalsavinguatadb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://digitalsavinguatadb2c.b2clogin.com/digitalsavinguatadb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://digitalsaving.portal.uat.asappbanking.com/dashboard',
  },
  'duca.portal.qa.asappbanking.com': {
    clientId: 'ae7f414c-aefd-4a1a-bc95-198da0208bc8',
    authority:
      'https://ducaqab2c.b2clogin.com/ducaqab2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://ducaqab2c.b2clogin.com/ducaqab2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri: 'https://duca.portal.qa.asappbanking.com/dashboard',
  },
  'engage.tcufinancialgroup.com': {
    clientId: '79159dca-02f3-4a18-a616-5b8e7d107d6e',
    authority:
      'https://tcufinancialb2c.b2clogin.com/tcufinancialb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tcufinancialb2c.b2clogin.com/tcufinancialb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://engage.tcufinancialgroup.com/dashboard',
  },
  'tcufinancial.portal.staging.asappbanking.com': {
    clientId: 'ca79629d-6f18-4e91-8dff-a68ea02047d1',
    authority:
      'https://tcufinancialstagingb2c.b2clogin.com/tcufinancialstagingb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://tcufinancialstagingb2c.b2clogin.com/tcufinancialstagingb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://tcufinancial.portal.staging.asappbanking.com/dashboard',
  },
  'asapp.v18.portal.test.asappbanking.com': {
    clientId: '3712afe2-2f81-4fa5-8cf4-5cd082273179',
    authority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://asapp.v18.portal.test.asappbanking.com/dashboard',
  },
  'cumisd.v18.portal.test.asappbanking.com': {
    clientId: '3712afe2-2f81-4fa5-8cf4-5cd082273179',
    authority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumisd.v18.portal.test.asappbanking.com/dashboard',
  },
  'cumish.v18.portal.test.asappbanking.com': {
    clientId: '3712afe2-2f81-4fa5-8cf4-5cd082273179',
    authority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumish.v18.portal.test.asappbanking.com/dashboard',
  },
  'xchange.v18.portal.test.asappbanking.com': {
    clientId: '3712afe2-2f81-4fa5-8cf4-5cd082273179',
    authority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://xchange.v18.portal.test.asappbanking.com/dashboard',
  },
  'rfs.v18.portal.test.asappbanking.com': {
    clientId: '3712afe2-2f81-4fa5-8cf4-5cd082273179',
    authority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://rfs.v18.portal.test.asappbanking.com/dashboard',
  },
  'mambu.v18.portal.test.asappbanking.com': {
    clientId: '3712afe2-2f81-4fa5-8cf4-5cd082273179',
    authority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://mambu.v18.portal.test.asappbanking.com/dashboard',
  },
  'wealthview.v18.portal.test.asappbanking.com': {
    clientId: '3712afe2-2f81-4fa5-8cf4-5cd082273179',
    authority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev18b2c.b2clogin.com/asappdev18b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://wealthview.v18.portal.test.asappbanking.com/dashboard',
  },
  'asapp.v19.portal.test.asappbanking.com': {
    clientId: '7fb7eae1-7d1e-4392-a24d-ffc27f61757d',
    authority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://asapp.v19.portal.test.asappbanking.com/dashboard',
  },
  'cumisd.v19.portal.test.asappbanking.com': {
    clientId: '7fb7eae1-7d1e-4392-a24d-ffc27f61757d',
    authority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumisd.v19.portal.test.asappbanking.com/dashboard',
  },
  'cumish.v19.portal.test.asappbanking.com': {
    clientId: '7fb7eae1-7d1e-4392-a24d-ffc27f61757d',
    authority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumish.v19.portal.test.asappbanking.com/dashboard',
  },
  'xchange.v19.portal.test.asappbanking.com': {
    clientId: '7fb7eae1-7d1e-4392-a24d-ffc27f61757d',
    authority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://xchange.v19.portal.test.asappbanking.com/dashboard',
  },
  'rfs.v19.portal.test.asappbanking.com': {
    clientId: '7fb7eae1-7d1e-4392-a24d-ffc27f61757d',
    authority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://rfs.v19.portal.test.asappbanking.com/dashboard',
  },
  'mambu.v19.portal.test.asappbanking.com': {
    clientId: '7fb7eae1-7d1e-4392-a24d-ffc27f61757d',
    authority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://mambu.v19.portal.test.asappbanking.com/dashboard',
  },
  'wealthview.v19.portal.test.asappbanking.com': {
    clientId: '7fb7eae1-7d1e-4392-a24d-ffc27f61757d',
    authority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappdev19b2c.b2clogin.com/asappdev19b2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://wealthview.v19.portal.test.asappbanking.com/dashboard',
  },
  'engage.crossroadscu.ca': {
    clientId: '978f971e-29c2-440d-bba0-0865e220d012',
    authority:
      'https://crossroadcu.b2clogin.com/crossroadcu.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://crossroadcu.b2clogin.com/crossroadcu.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://engage.crossroadscu.ca/dashboard',
  },
  'engage.cua.com': {
    clientId: 'b501640c-b580-4101-b35d-f89dffc17038',
    authority:
      'https://cuadnaadb2c.b2clogin.com/cuadnaadb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://cuadnaadb2c.b2clogin.com/cuadnaadb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://engage.cua.com/dashboard',
  },
  'oldengage.cua.com': {
    clientId: '5ea4cdc5-ffd4-4fe4-b8b6-44cb7cf3ca19',
    authority:
      'https://oldcuaprodadb2c.b2clogin.com/oldcuaprodadb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://oldcuaprodadb2c.b2clogin.com/oldcuaprodadb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://oldengage.cua.com/dashboard',
  },
  'asapp.v20.portal.test.asappbanking.com': {
    clientId: 'cb0220b9-47f3-4420-88c4-06f47ffd3a01',
    authority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://asapp.v20.portal.test.asappbanking.com/dashboard',
  },
  'cumisd.v20.portal.test.asappbanking.com': {
    clientId: 'cb0220b9-47f3-4420-88c4-06f47ffd3a01',
    authority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumisd.v20.portal.test.asappbanking.com/dashboard',
  },
  'cumish.v20.portal.test.asappbanking.com': {
    clientId: 'cb0220b9-47f3-4420-88c4-06f47ffd3a01',
    authority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://cumish.v20.portal.test.asappbanking.com/dashboard',
  },
  'xchange.v20.portal.test.asappbanking.com': {
    clientId: 'cb0220b9-47f3-4420-88c4-06f47ffd3a01',
    authority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://xchange.v20.portal.test.asappbanking.com/dashboard',
  },
  'rfs.v20.portal.test.asappbanking.com': {
    clientId: 'cb0220b9-47f3-4420-88c4-06f47ffd3a01',
    authority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://rfs.v20.portal.test.asappbanking.com/dashboard',
  },
  'mambu.v20.portal.test.asappbanking.com': {
    clientId: 'cb0220b9-47f3-4420-88c4-06f47ffd3a01',
    authority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://mambu.v20.portal.test.asappbanking.com/dashboard',
  },
  'wealthview.v20.portal.test.asappbanking.com': {
    clientId: 'cb0220b9-47f3-4420-88c4-06f47ffd3a01',
    authority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://asappv20adb2c.b2clogin.com/asappv20adb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://wealthview.v20.portal.test.asappbanking.com/dashboard',
  },
  'northernsavings.portal.uat.asappbanking.com': {
    clientId: '5c4b8cf2-efee-4949-b57b-53fa5528d4a9',
    authority:
      'https://northernsavingsuatadb2c.b2clogin.com/northernsavingsuatadb2c.onmicrosoft.com/B2C_1_signin',
    resetPasswordAuthority:
      'https://northernsavingsuatadb2c.b2clogin.com/northernsavingsuatadb2c.onmicrosoft.com/B2C_1_ResetPassword',
    redirectUri:
      'https://northernsavings.portal.uat.asappbanking.com/dashboard',
  },
};

export function getCurrentClientId(): string {
  return orgName[window.location.hostname].clientId;
}

export function getCurrentResetPasswordAuthority(): string {
  return orgName[window.location.hostname].resetPasswordAuthority;
}

export function getCurrentResetPasswordPolicyName(): string {
  const authority = orgName[window.location.hostname].resetPasswordAuthority;
  return authority.slice(authority.lastIndexOf('/') + 1);
}

export function MSALConfigFactory(): IPublicClientApplication {
  const authority = orgName[window.location.hostname].authority;
  const tenantName = authority.match(/https:\/\/(.*).b2clogin.com/)[1];

  return new PublicClientApplication({
    auth: {
      clientId: orgName[window.location.hostname].clientId,
      authority: orgName[window.location.hostname].authority,
      knownAuthorities: [`${tenantName}.b2clogin.com`],
      //  validateAuthority: false,
      redirectUri: orgName[window.location.hostname].redirectUri,
      postLogoutRedirectUri: orgName[window.location.hostname].redirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      tokenRenewalOffsetSeconds: 1800,
    },
  });
}
